exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coaching-tsx": () => import("./../../../src/pages/coaching.tsx" /* webpackChunkName: "component---src-pages-coaching-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offline-tsx": () => import("./../../../src/pages/offline.tsx" /* webpackChunkName: "component---src-pages-offline-tsx" */),
  "component---src-pages-over-petra-tsx": () => import("./../../../src/pages/over-petra.tsx" /* webpackChunkName: "component---src-pages-over-petra-tsx" */),
  "component---src-pages-programmas-tsx": () => import("./../../../src/pages/programmas.tsx" /* webpackChunkName: "component---src-pages-programmas-tsx" */),
  "component---src-pages-werkwijze-tsx": () => import("./../../../src/pages/werkwijze.tsx" /* webpackChunkName: "component---src-pages-werkwijze-tsx" */)
}

